'use client'

import React, { createContext, PropsWithChildren } from 'react'
import { ISetting } from '@/entities/setting'

type SettingsContext = {
  settings: ISetting[]
}

export const SettingsContext = createContext<SettingsContext | null>(null);

type TProps = {
  settings: ISetting[]
}

export default function SettingsContextProvider({ children, settings }: TProps & PropsWithChildren) {
  return (
    <SettingsContext.Provider value={{ settings }}>
      {children}
    </SettingsContext.Provider>
  )
}

export function useSettingsContext() {
  const context = React.useContext(SettingsContext);
  if (!context) {
    throw new Error('useSettingsContext must be used within a SettingsContextProvider');
  }

  return context;
}
