import Image from "next/image";
import classNames from 'classnames'

type TProps = {
  labels: string[],
}

const BrandAnimateLine = ({labels = []}: TProps) => {
	return (
		<div className="scrolling-line__wrapper">
			<div className="scrolling-line__brand-viewport">
				<div className="scrolling-line__brand-viewport-inner">
					{
						['first-list', 'second-list'].map((className, index) => {
							return <div key={index} className={classNames('scrolling-line__brand-list', className)}>
								{
									labels.map((label, indexLabel) =>
										<span key={indexLabel} style={{display: 'flex', alignItems: 'center', flexShrink: 0}}>
											<span className="brand-separator">{label}</span>
											<span className="brand-separator">
												<Image
													src="/static/images/logo-2.svg"
													alt="logo"
													width={306} 
													height={70} />
											</span>
										</span>
									)
								}
							</div>
						})
					}
				</div>
			</div>
		</div>
	)
}

export default BrandAnimateLine