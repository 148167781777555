type TProps = {
  counter: number,
  setCounter: (value: number) => void
}

const QuantityCounter = ({counter, setCounter}: TProps) => {
  return (
    <div className='cart-counter'>
      <button className='minus' onClick={() => setCounter(Math.max(1, counter - 1))}></button>
      <input type='number' value={counter} onChange={(e) => setCounter(+e.target.value)} />
      <button className='plus' onClick={() => setCounter(counter + 1)}></button>
    </div>
  )
}

export default QuantityCounter