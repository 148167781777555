'use client'

import {useTranslations} from "next-intl";
import { SubmitHandler, useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { QuestionSuccessModal } from '@/components/modal'
import classNames from 'classnames'

type FormValues = {
	name: string,
	phone: string,
	text: string
}

const ContactForm = () => {
	const t = useTranslations()

	const {
		register,
		handleSubmit,
		setError,
		reset,
		formState: { isSubmitSuccessful, isLoading, isSubmitting, errors }
	} = useForm<FormValues>();

	useEffect(() => {
		reset({
			name: '',
			phone: '',
			text: '',
		})
	}, [isSubmitSuccessful, reset])

	const onSubmit: SubmitHandler<FormValues> = async data => {
		const response = await fetch('/api/question', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data),
		})

		if (!response.ok) {
			if (response?.status == 422) {
				const errorData = await response.json();
				(Object.keys(errorData.errors) as Array<keyof FormValues>).forEach((key, i) => {
					setError(key, { type: 'custom', message: errorData.errors[key].join(' ') });
				})
			}
		} else {
			setIsQuestionSuccessModelShown(true)
		}
	};

	const [isQuestionSuccessModelShown, setIsQuestionSuccessModelShown] = useState<boolean>(false)

	return (
		<>
			<div className="col-lg-7">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						<div className="col-lg-6 position-relative">
							<label htmlFor="form-name">{t('pages.contacts.question.form.name-label')}</label>
							<input
								{...register("name", { required: t('validation.required', {attribute:  t('validation.attributes.name')}) })}
								type="text"
								id="form-name"
								className={classNames({'is-invalid': !!errors.name})}
								placeholder={t('pages.contacts.question.form.name-placeholder')}
								disabled={isLoading || isSubmitting}
							/>
							{errors.name && <div className="invalid-feedback">{errors.name.message}</div>}
						</div>
						<div className="col-lg-6 position-relative">
							<label htmlFor="form-tel">{t('pages.contacts.question.form.phone-label')}</label>
							<input
								{...register("phone", { required: t('validation.required', {attribute:  t('validation.attributes.phone')}) })}
								type="tel"
								id="form-tel"
								className={classNames({'is-invalid': !!errors.phone})}
								placeholder="+38 050 123-45-67"
								disabled={isLoading || isSubmitting}
							/>
							{errors.phone && <div className="invalid-feedback">{errors.phone.message}</div>}
						</div>
						<div className="col-12 position-relative">
							<label htmlFor="form-text">{t('pages.contacts.question.form.comment-label')}</label>
							<textarea
								{...register("text", { required: t('validation.required', {attribute:  t('validation.attributes.comment')}) })}
								id="form-text"
								cols={30}
								rows={5}
								className={classNames({'is-invalid': !!errors.text})}
								placeholder={t('pages.contacts.question.form.comment-placeholder')}
								disabled={isLoading || isSubmitting}
							></textarea>
							{errors.text && <div className="invalid-feedback">{errors.text.message}</div>}
						</div>
					</div>
					<div className="form-action pt-3">
						<button
							type="submit"
							className="submit-btn"
							disabled={isLoading || isSubmitting}
						>
							{t('pages.contacts.question.form.submit')}
						</button>
						<div className="arrow-right"></div>
					</div>
				</form>
			</div>

			{isQuestionSuccessModelShown && (
				<QuestionSuccessModal
					close={() => setIsQuestionSuccessModelShown(false)}
				/>
			)}
		</>
	)
}

export default ContactForm
