import classNames from 'classnames'

type TProps = {
	value?: number,
	onChange: (value?: number) => void,
	disabled: boolean,
  labels: string[],
}

const Stars = ({value, onChange, disabled, labels}: TProps) => {
  return (
    <div
      className={classNames('c-rating c-rating-clickable', {'disabled': disabled})}
      data-rating-value={value}
      title={`${value}`}
    >
      {
        Array.from({ length: 5 }).map((_, index) => {
          return (
            <div className='star' key={index}>
              <button
                type='button'
                disabled={disabled}
                onClick={() => onChange(index + 1)}
              ></button>
            </div>
          )
        })
      }
    </div>
  )
}

export default Stars