type TProps = {
  title: string,
  textColor: string,
  backgroundColor: string,
}

const Badge = ({title, textColor, backgroundColor}: TProps) => {
  return (
    <div className="label" style={{ color: `#${textColor}` }}>
      <span className="bg-layout-1" style={{ backgroundColor: `#${backgroundColor}`}}></span>
      <span className="bg-layout-2" style={{ backgroundColor: `#${backgroundColor}`}}></span>
      <span className="bg-layout-3" style={{ backgroundColor: `#${backgroundColor}`}}></span>
      {title}
    </div>
  )
}

export default Badge