import { getServerSession } from 'next-auth'
import { notFound, redirect } from 'next/navigation'
import { authOptions } from '@/libs/auth'

export async function fetcher<T = unknown>(
  endpoint: string,
  params?: Record<string, string | undefined>,
  options?: RequestInit,
) {
  const session = await getServerSession(authOptions)

  const requestHeaders = {
    ...options?.headers,
  } as Record<string, string>

  const clientAccessToken = session?.user?.access_token

  if (clientAccessToken) {
    requestHeaders.Authorization = `Bearer ${clientAccessToken}`
  }

  const url = new URL(`${process.env.API_HOST}${endpoint}`)

  for (let key in params) {
    if (params[key] === undefined || params[key] === null || params[key] === '') {
      delete params[key];
    }
  }

  if (params) {
    url.search = new URLSearchParams(params as Record<string, string>).toString();
  }

  return fetch(url, {
    method: options?.method,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      ...requestHeaders,
    },
    body: options?.body,
    cache: options?.cache,
    next: options?.next,
  }).then(response => {

    if (response.status === 404) {
      notFound();
    }

    if (response.status === 401) {
      redirect('/logout')
    }

    return response
  }).then((response) => response.json()) as Promise<T>
}
