'use client'

import Modal from '@/components/simple/Modal'
import { SubmitHandler, useForm } from 'react-hook-form'
import { signIn } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/navigation'
import { useState } from 'react'

type FormValues = {
  phone: string,
  code: string
}

type TProps = {
  openLoginModalAction: () => void
  close: () => void
}

const OTPPhoneLoginModal = ({ openLoginModalAction, close }: TProps) => {
  const t = useTranslations('dialogs.otp-phone-login')
  const router = useRouter()
  const [isCodeFieldShown, setIsCodeFieldShown] = useState<boolean>(false)
  const [isCodeSending, setIsCodeSending] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    setError,
    getValues,
    formState: { isSubmitSuccessful, isLoading, isSubmitting, errors },
  } = useForm<FormValues>()

  const handleCodeSendAction = async () => {
    setIsCodeFieldShown(false)
    setIsCodeSending(true)

    const response = await fetch('/api/otp', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ phone: getValues('phone') }),
    })

    setIsCodeSending(false)

    if (!response.ok) {
      if (response?.status == 422) {
        const errorData = await response.json();
        (Object.keys(errorData.errors) as Array<keyof FormValues>).forEach((key, i) => {
          setError(key, { type: 'custom', message: errorData.errors[key].join(' ') })
        })
      }
    } else {
      setIsCodeFieldShown(true)
    }
  }

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const signInResponse = await signIn('otp-phone', {
      redirect: false,
      // callbackUrl: '/account',
      ...data,
    })

    if (signInResponse) {
      if (signInResponse?.status >= 400) {
        setError('code', { type: 'custom', message: t('form.error-message') })
      } else {
        router.push('/account')
      }
    }
  }

  const footer = (
    <div className='container-fluid'>
      <div className='row'>
        {!isCodeFieldShown && (
          <div className='col-12'>
            <button
              type='button'
              className='green-btn'
              form='login-form'
              disabled={isLoading || isSubmitting || isCodeSending}
              onClick={handleCodeSendAction}
            >
              {t('footer.send')}
            </button>
          </div>
        )}

        {isCodeFieldShown && (
          <div className='col-12'>
            <button
              type='submit'
              className='green-btn'
              form='login-form'
              disabled={isLoading || isSubmitting || isCodeSending}
            >
              {t('footer.enter')}
            </button>
          </div>
        )}

        <div className='col-12 d-flex justify-content-end'>
          <button
            type='button'
            className='underline-btn'
            onClick={openLoginModalAction}
            disabled={isLoading || isSubmitting || isCodeSending}
          >
            {t('footer.login-by-email')}
          </button>
        </div>
      </div>
    </div>
  )

  return (
    <Modal title={t('title')} footer={footer} close={close}>
      <form onSubmit={handleSubmit(onSubmit)} id={'login-form'}>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12 position-relative'>
              <label htmlFor='phone'>{t('form.phone-label')}</label>
              <input
                {...register('phone', { required: true })}
                type='text'
                id='phone'
                placeholder='+380951234567'
                disabled={isLoading || isSubmitting || isCodeSending}
              />

              {errors.phone && <div className='error-message'>{errors.phone.message}</div>}
            </div>

            {isCodeFieldShown && (
              <div className='col-12 position-relative'>
                <label htmlFor='code'>{t('form.code-label')}</label>
                <input
                  {...register('code', { required: true })}
                  type='text'
                  id='code'
                  placeholder='Code'
                  disabled={isLoading || isSubmitting || isCodeSending}
                />

                {errors.code && <div className='error-message'>{errors.code.message}</div>}
              </div>
            )}
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default OTPPhoneLoginModal
