'use client'

import Image from "next/image";
import NextLink from "next/link";
import {useTranslations} from 'next-intl'
import { useRouter } from 'next/navigation'

type TProps = {
	title: string
	cover: string
	alias: string
}

const CatalogCard = ({title, cover, alias}: TProps) => {
	const t = useTranslations('pages.catalog.catalog-slider.item')
	const router = useRouter()

	return (
		<>
			<div
				className="item-slide cursor-pointer"
				onClick={() => router.push(`/catalog/${alias}`, { scroll: false })}
			>
				<div
					className="img-box position-relative"
					style={{width: '100%', height: '500px', margin: '0 auto'}}
				>
					<Image
						src={cover}
						alt={title}
						fill
						quality={100}
						className="object-fit-contain"
					/>
				</div>
				<div className="bottom-info">
					<div className="title">{title}</div>
					<NextLink href={`/catalog/${alias}`} className="green-btn d-inline-flex">
						{t('show')}
					</NextLink>
				</div>
			</div>
		</>
	)
}

export default CatalogCard

