import { useContext } from 'react'

import { ShoppingCartContext } from '@/context/shopping-cart-context'

export const useShoppingCart = () => {
  const context = useContext(ShoppingCartContext);
  if (!context) {
    throw new Error('useShoppingCartContext must be used within a ShoppingCartContextProvider');
  }

  return context;
}