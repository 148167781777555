import { NextAuthOptions } from 'next-auth'
import CredentialsProvider from 'next-auth/providers/credentials'

export const authOptions: NextAuthOptions = {
  providers: [
    CredentialsProvider({
      id: 'otp-phone',
      name: 'Phone',
      credentials: {
        phone: { label: 'Phone', type: 'tel', placeholder: 'Your phone' },
        code: { label: 'Code', type: 'text', placeholder: 'Code' },
      },
      async authorize(credentials) {
        const data = {
          phone: credentials?.phone,
          code: credentials?.code,
        }

        const headers = new Headers({
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        })

        const options = {
          method: 'POST',
          headers,
          body: JSON.stringify(data),
        }

        try {
          const response = await fetch(`${process.env.API_HOST}/api/v1/otp/login`, options)

          if (response.ok) {
            return await response.json()
          } else {
            console.log('HTTP error! Status:', response.status)
            // return { error: "Authentication failed" }
            return null
          }
        } catch (error) {
          console.log('Error', error)
        }

        return null
      },
    }),
    CredentialsProvider({
      id: 'email-and-password',
      name: 'Email and Password',
      credentials: {
        email: { label: 'Email', type: 'email', placeholder: 'Your Email' },
        password: { label: 'Password', type: 'password' },
      },
      async authorize(credentials) {
        /*
        const res = await fetch(`${process.env.API_HOST}/sanctum/csrf-cookie`, {
          method: 'GET',
        })

        const setCookieHeader = res.headers.get('set-cookie')

        const cookies = setCookieHeader?.split(', ')

        let sessionKey = null
        let xsrfToken = null

        for (const cookie of cookies!) {
          if (cookie.startsWith('laravel_session=')) {
            sessionKey = cookie.split('=')[1]
          } else if (cookie.startsWith('XSRF-TOKEN=')) {
            xsrfToken = cookie.split('=')[1]
          }

          if (sessionKey && xsrfToken) {
            break
          }
        }
        */

        const data = {
          email: credentials?.email,
          password: credentials?.password,
        }

        const headers = new Headers({
          // Cookie: `laravel_session=${sessionKey}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        })

        // if (xsrfToken) {
        //   headers.append('X-XSRF-TOKEN', xsrfToken)
        // }

        const options = {
          method: 'POST',
          headers,
          body: JSON.stringify(data),
        }

        try {
          const response = await fetch(`${process.env.API_HOST}/api/v1/login`, options)

          if (response.ok) {
            return await response.json()
          } else {
            console.log('HTTP error! Status:', response.status)
            // return { error: "Authentication failed" }
            return null
          }
        } catch (error) {
          console.log('Error', error)
        }

        return null
      },
    }),
  ],
  callbacks: {
    async jwt({ token, account, user }) {
      if (user) {
        token.user = user
        token.accessToken = user.access_token
      }
      return token
    },
    async session({ session, token }) {
      session.accessToken = token.access_token
      session.user = token.user
      return session
    },
  },
  pages: {
    // signIn: '/',
    // signOut: '/auth/signout',
  },
}
