import { PropsWithChildren, ReactNode } from 'react'
import classNames from 'classnames'

type TProps = {
  title: string,
  footer?: ReactNode
  close: () => void
  className?: string,
} & PropsWithChildren

const Modal = ({title, children, footer, className, close}: TProps) => {
  return (
    <div className="modal-backdrop w-100 h-100" style={{background: 'rgba(0, 0, 0, 0.8)'}}>
      <div className={classNames("modal custom-modal fade show", className)} style={{display: 'block'}}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title m-0">{title}</h5>
              <button type="button" className="btn-close" aria-label="Close" onClick={close}></button>
            </div>
            <div className="modal-body">
              {children}
            </div>
            {footer && (
              <div className="modal-footer">
                {footer}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
