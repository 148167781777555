'use client'

import Image from 'next/image'
import { useTranslations } from 'next-intl'
import { useSettingsContext } from '@/context/settings-context'
import { ESettingKey } from '@/services/settings'
import { ISettingValueMap } from '@/entities/setting'
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api'
import React, { useMemo } from 'react'

type TProps = {
  googleMapsApiKey: string,
}

const ContactInformation = ({ googleMapsApiKey }: TProps) => {
  const t = useTranslations('pages.contacts.question.information')

  const { settings } = useSettingsContext()

  const phone = settings.find(x => x.key === ESettingKey.PHONE)?.value as string
  const email = settings.find(x => x.key === ESettingKey.EMAIL)?.value as string
  const address = settings.find(x => x.key === ESettingKey.ADDRESS)?.value as string
  const schedule = settings.find(x => x.key === ESettingKey.WORK_SCHEDULE)?.value as string
  const facebook = settings.find(x => x.key === ESettingKey.SOCIAL_FACEBOOK)?.value as string
  const instagram = settings.find(x => x.key === ESettingKey.SOCIAL_INSTAGRAM)?.value as string
  const youtube = settings.find(x => x.key === ESettingKey.SOCIAL_YOUTUBE)?.value as string

  const coordinates = settings.find(x => x.key === ESettingKey.MAP_LOCATION)?.value as ISettingValueMap

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey,
    libraries: ['places'],
  })

  const mapOptions = useMemo<google.maps.MapOptions>(
    () => ({
      disableDefaultUI: true,
      clickableIcons: true,
      scrollwheel: false,
      navigationControl: false,
      zoomControl: true,
    }),
    [],
  )

  return (
    <div className='col-lg-5 contacts-info'>
      <div className='row'>
        <div className='col-lg-6'>
          <h5>{t('contacts-label')}</h5>

          <ul className='contacts-list'>
            {phone && (<li><a href={`tel:${phone}`}>{phone}</a></li>)}
            {email && (<li><a href={`mailto:${email}`}>{email}</a></li>)}
          </ul>

          {schedule && (
            <>
              <h5>{t('schedule-label')}</h5>
              <p>{schedule}</p>
            </>
          )}

          <ul className='social-networks'>
            {facebook && (<li>
              <a href={facebook} target='_blank'>
                <Image
                  src='/static/images/icons/facebook.svg'
                  alt='facebook'
                  width={26}
                  height={26}
                />
              </a>
            </li>)}

            {youtube && (<li>
              <a href={youtube} target='_blank'>
                <Image
                  src='/static/images/icons/youtube.svg'
                  alt='youtube'
                  width={26}
                  height={26}
                />
              </a>
            </li>)}

            {instagram && (<li>
              <a href={instagram} target='_blank'>
                <Image
                  src='/static/images/icons/instagram.svg'
                  alt='instagram'
                  width={26}
                  height={26}
                />
              </a>
            </li>)}
          </ul>
        </div>

        <div className='col-lg-6'>
          <h5>{t('address-label')}</h5>

          {address && (<p>{address}</p>)}

          {isLoaded && (<div className='map-box'>
            <GoogleMap
              options={mapOptions}
              zoom={14}
              center={{ lat: coordinates.latitude, lng: coordinates.longitude }}
              mapContainerStyle={{ height: '300px', width: '100%' }}
            >
              <MarkerF position={{ lat: coordinates.latitude, lng: coordinates.longitude }} />
            </GoogleMap>
          </div>)}
        </div>
      </div>
    </div>
  )
}

export default ContactInformation
