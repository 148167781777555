import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from 'react'
import { IShoppingCart } from '@/entities'

interface IShoppingCartContext {
  shoppingCart: IShoppingCart
  amount: number
  setShoppingCart: Dispatch<SetStateAction<IShoppingCart>>
}

export const ShoppingCartContext = createContext<IShoppingCartContext>({} as IShoppingCartContext);

interface IShoppingCartContextProps {
  initialShoppingCart?: IShoppingCart
}

export const ShoppingCartContextProvider = ({ children, initialShoppingCart }: IShoppingCartContextProps & PropsWithChildren) => {
  const [
    shoppingCart,
    setShoppingCart
  ] = useState(initialShoppingCart || {list: [], promo: null})

  const amount = shoppingCart
    .list
    .map(x => x.price * x.quantity)
    .reduce((accumulator, currentValue) => {
      return accumulator + currentValue
    }, 0)

  return (
    <ShoppingCartContext.Provider
      value={{
        shoppingCart: shoppingCart,
        setShoppingCart: setShoppingCart,
        amount
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  )
}