'use client'

import React, { useMemo, useState, useRef, useEffect } from 'react'
import { useTranslations } from 'next-intl'
import classNames from 'classnames'
import { GoogleMap, MarkerF, useJsApiLoader, InfoWindowF } from '@react-google-maps/api'
import { useSettingsContext } from '@/context/settings-context'
import { ISettingValueMap } from '@/entities/setting'
import { IDealer } from '@/entities'

type TProps = {
  dealers: IDealer[],
  googleMapsApiKey: string
}

const DealersOnMap = ({ dealers, googleMapsApiKey }: TProps) => {

  const t = useTranslations('pages.dealers.google-map')
  const { settings } = useSettingsContext()
  const [defaultCenterPosition, setDefaultCenterPosition] = useState<ISettingValueMap | undefined>()
  const [searchText, setSearchText] = useState<string>('')
  const [dealerList, setDealerList] = useState<IDealer[]>(dealers)
  const [currentMarkerIndex, setCurrentMarkerIndex] = useState<number>()

  useEffect(() => {
    const position = settings?.find(({key}) => key === "mapLocation")?.value as ISettingValueMap | undefined
    setDefaultCenterPosition(position)
  }, [settings])

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchInputFieldText = e.target.value
    setSearchText(searchInputFieldText)

    const filtered = dealers
      .filter((x) => {
        return x.title.toLowerCase().includes(searchInputFieldText.toLowerCase())
          || x.address
            .replace(/[\r\n]/gm, ' ')
            .toLowerCase()
            .includes(searchInputFieldText.toLowerCase())
      })

    setDealerList(filtered)
  }

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey,
    libraries: ['places'],
  })

  const mapOptions = useMemo<google.maps.MapOptions>(
    () => ({
      disableDefaultUI: true,
      clickableIcons: true,
      scrollwheel: false,
      navigationControl: true,
      zoomControl: true,
    }),
    [],
  )

  const centerDealer = useRef<IDealer | undefined>(dealers?.[0])

  const changeCurrentMarkerIndex = (index: number) => {
    const currentIndex = index === currentMarkerIndex ? undefined : index
    setCurrentMarkerIndex(currentIndex)
    centerDealer.current = currentIndex ? dealerList[currentIndex] : dealerList[0]
  }

  return (
    <div className='bottom-content'>
      <div className='container-fluid map-wrapper'>
        <div className='row'>
          <div className='col-lg-3 dealers-list'>
            <form action='#'>
              <h5>{t('search-form.title')}</h5>
              <div className='form-row'>
                <input
                  type='text'
                  value={searchText}
                  onInput={handleSearchInput}
                  placeholder={t('search-form.location-placeholder')}
                />
              </div>
              <div className='form-action'>
                <button className='submit-btn'>{t('search-form.submit')}</button>
              </div>
            </form>
            <div className='scroll-wrap'>
              <ul>
                {dealerList.map((x, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => changeCurrentMarkerIndex(index)}
                      className={classNames({ 'active': currentMarkerIndex === index })}
                    >
                      <div className='num-dealers'>{index + 1}</div>
                      <div className='name'>{x.title}</div>
                      <p>{x.address}</p>
                      {!!x.phones && x.phones.split(',').map((phone, phoneIndex) => {
                        return (<p key={phoneIndex} className='tel m-0'>{phone}</p>)
                      })}
                      <a className="d-inline-block mt-2" href={`mailto:${x.email}`}>{x.email}</a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          {isLoaded && (
            <div className='col-lg-9 p-0 map-box'>
              <GoogleMap
                options={mapOptions}
                zoom={14}
                center={
                  centerDealer.current 
                    ? { lat: centerDealer.current.latitude, lng: centerDealer.current.longitude } 
                    : defaultCenterPosition 
                      ? { lat: defaultCenterPosition.latitude, lng: defaultCenterPosition.longitude } 
                      : undefined
                }
                mapContainerStyle={{ height: '760px', width: '100%' }}
              >
                {dealerList.map((x, index) => {
                  return (
                    <MarkerF
                      key={index}
                      position={{ lat: x.latitude, lng: x.longitude }}
                      icon='/static/images/icons/map-marker.svg'
                      label={{ color: '#fff', fontSize: '20px', text: `${index + 1}`, className: 'marker-label' }}
                      onClick={() => changeCurrentMarkerIndex(index)}
                    >
                      {currentMarkerIndex === index && (
                        <InfoWindowF
                          position={{ lat: x.latitude, lng: x.longitude }}
                          onCloseClick={() => setCurrentMarkerIndex(undefined)}
                        >
                          <div style={{ color: '#000' }}>
                            <div>{x.title}</div>
                            <p className='m-0'>{x.address}</p>
                            {!!x.phones && (
                              x.phones.split(',').map((phone, index) => {
                                return <p key={index} className='m-0'>{phone}</p>
                              })
                            )}
                          </div>
                        </InfoWindowF>
                      )}
                    </MarkerF>
                  )
                })}
              </GoogleMap>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DealersOnMap