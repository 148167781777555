'use client'

import Modal from '@/components/simple/Modal'
import { useTranslations } from 'next-intl'
import { SubmitHandler, useForm } from 'react-hook-form'

type FormValues = {
  email: string,
}

type TProps = {
  openLoginModalAction: () => void
  close: () => void
}

const ForgotPasswordModal = ({openLoginModalAction, close}: TProps) => {
  const t = useTranslations('dialogs.forgot-password')

  const {
    register,
    handleSubmit,
    setError,
    getValues,
    formState: { isSubmitSuccessful, isLoading, isSubmitting, errors },
  } = useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const response = await fetch('/api/reset-password', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })

    if (!response.ok) {
      if (response?.status == 422) {
        const errorData = await response.json();
        (Object.keys(errorData.errors) as Array<keyof FormValues>).forEach((key, i) => {
          setError(key, { type: 'custom', message: errorData.errors[key].join(' ') })
        })
      }
    } else {
      openLoginModalAction()
    }
  }

  const footer = (
    <div className="container-fluid">
      <div className="row">
        <div className='col-12'>
          <button
            type='submit'
            className='green-btn'
            form='login-form'
            disabled={isLoading || isSubmitting}
          >
            {t('footer.submit')}
          </button>
        </div>

        <div className='col-12 d-flex justify-content-end'>
          <button
            type='button'
            className='underline-btn'
            onClick={openLoginModalAction}
            disabled={isLoading || isSubmitting}
          >
            {t('footer.cancel')}
          </button>
        </div>
      </div>
    </div>
  )

  return (
    <Modal title={t('title')} footer={footer} close={close}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
          <p className="text-dark p-0 m-0">{t('content')}</p>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} id={'login-form'}>
        <div className="container-fluid">
        <div className='row'>
            <div className='col-12 position-relative'>
              <label htmlFor='phone'>{t('form.email-label')}</label>
              <input
                {...register('email', { required: true })}
                type='text'
                id='phone'
                placeholder='mykola@gmail.com'
                disabled={isLoading || isSubmitting}
              />
              {errors.email && <div className='error-message'>{errors.email.message}</div>}
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default ForgotPasswordModal
