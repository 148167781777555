import Modal from '@/components/simple/Modal'
import { useTranslations } from 'next-intl'

type TProps = {
  close: () => void
}

const CallbackSuccessModal = ({close}: TProps) => {
  const t = useTranslations('dialogs.callback-success-modal')

  const footer = (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="wrap-arrows-button">
            <button
              type="button"
              className="green-btn"
              onClick={close}
            >
              {t('ok')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <Modal title={t('title')} footer={footer} close={close}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <p className="text-dark p-0 m-0">{t.rich('content', { br: () => <br/>})}</p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CallbackSuccessModal
