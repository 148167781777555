'use client'

import classNames from 'classnames'

const ScrollToTop = ({className}: {className?: string}) => {
	const handleScrollToTop = () => {
		window.scrollTo({top: 0, behavior: 'smooth'});
	}

	return <div className={classNames("scroll-up-box pt-3", className)}>
		<button
			type="button"
			aria-label="Scroll to top"
			onClick={() => handleScrollToTop()}
		></button>
	</div>
}

export default ScrollToTop