import { Locale } from 'date-fns'

export interface IPaginatorResponse<T> {
	data: T[]
	current_page: number
	first_page_url: string
	links: {
		url: string | null,
		active: boolean
		label: string
	}[]
	per_page: number
	last_page: number
}

export enum EPropertyLabel {
	PROPERTY_ENGINE = 'engine',
	PROPERTY_DIAMETER = 'diameter',
	PROPERTY_PARTS = 'parts'
}

export enum EProperty {
	PROPERTY_ENGINE = 1,
	PROPERTY_DIAMETER = 2,
	PROPERTY_PARTS = 3,
}

export enum EPropertyIcon {
	PROPERTY_ENGINE = '/static/images/properties/engine-white.svg',
	PROPERTY_DIAMETER = '/static/images/properties/diameter-white.svg',
	PROPERTY_PARTS = '/static/images/properties/parts-white.svg',
}

export enum EPropertyHoverIcon {
	PROPERTY_ENGINE = '/static/images/properties/engine-blue.svg',
	PROPERTY_DIAMETER = '/static/images/properties/diameter-blue.svg',
	PROPERTY_PARTS = '/static/images/properties/parts-blue.svg',
}

export enum EPropertyOptionLabel {
	PROPERTY_OPTION_GASOLINE = 'gasoline',
	PROPERTY_OPTION_DIESEL = 'diesel',
	PROPERTY_OPTION_ELECTRIC = 'electric',
	PROPERTY_OPTION_DIAMETER_80 = '80',
	PROPERTY_OPTION_DIAMETER_120 = '120',
	PROPERTY_OPTION_DIAMETER_160 = '160',
	PROPERTY_OPTION_DIAMETER_180 = '180'
}

export enum EPropertyOption {
	PROPERTY_OPTION_GASOLINE = 1,
	PROPERTY_OPTION_DIESEL = 2,
	PROPERTY_OPTION_ELECTRIC = 3,
	PROPERTY_OPTION_DIAMETER_80 = 4,
	PROPERTY_OPTION_DIAMETER_120 = 5,
	PROPERTY_OPTION_DIAMETER_160 = 6,
	PROPERTY_OPTION_DIAMETER_180 = 7
}

export enum EPropertyOptionIcon {
	PROPERTY_OPTION_GASOLINE = '/static/images/properties/gasoline-white.svg',
	PROPERTY_OPTION_DIESEL = '/static/images/properties/diesel-white.svg',
	PROPERTY_OPTION_ELECTRIC = '/static/images/properties/electric-white.svg',
	PROPERTY_OPTION_DIAMETER_80 = '/static/images/properties/diameter-white.svg',
	PROPERTY_OPTION_DIAMETER_120 = '/static/images/properties/diameter-white.svg',
	PROPERTY_OPTION_DIAMETER_160 = '/static/images/properties/diameter-white.svg',
	PROPERTY_OPTION_DIAMETER_180 = '/static/images/properties/diameter-white.svg',
}

export enum EPropertyOptionHoverIcon {
	PROPERTY_OPTION_GASOLINE = '/static/images/properties/gasoline-blue.svg',
	PROPERTY_OPTION_DIESEL = '/static/images/properties/diesel-blue.svg',
	PROPERTY_OPTION_ELECTRIC = '/static/images/properties/electric-blue.svg',
	PROPERTY_OPTION_DIAMETER_80 = '/static/images/properties/diameter-blue.svg',
	PROPERTY_OPTION_DIAMETER_120 = '/static/images/properties/diameter-blue.svg',
	PROPERTY_OPTION_DIAMETER_160 = '/static/images/properties/diameter-blue.svg',
	PROPERTY_OPTION_DIAMETER_180 = '/static/images/properties/diameter-blue.svg',
}

export enum EOrderStatus {
	STATUS_CREATED,
	STATUS_IN_PROGRESS = 1,
	STATUS_COMPLETED = 2,
	STATUS_CANCELED = 3
}

export enum EOrderServiceStatus {
	STATUS_CREATED = 0,
	STATUS_IN_PROGRESS = 1,
	STATUS_COMPLETED = 2,
	STATUS_CANCELED = 3,
}

export enum EDeliveryType {
	TYPE_COMPANY_NOVA_POSHTA = 0,
	TYPE_COMPANY_NOVA_POSHTA_COURIER = 1,
	TYPE_COMPANY_CAT = 2,
	TYPE_COMPANY_DELIVERY = 3,
	TYPE_SELF_PICKUP = 4,
	TYPE_COMPANY_PALCHE = 5
}

export enum EPaymentType {
	TYPE_CASH = 0,
	TYPE_IMPOSED_PAYMENT = 1,
	TYPE_ONLINE = 2,
	TYPE_PART_PAYMENT = 3,
}

export enum EPromoCodeType {
	PERCENTAGE,
	FIXED_VALUE
}

export interface DateLocales {
	[key: string]: Locale; // Define index signature
}