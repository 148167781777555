'use client'

import Modal from '@/components/simple/Modal'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { useTranslations } from 'next-intl'
import { PatternFormat } from 'react-number-format'

type FormValues = {
  name: string,
  phone: string
}

type TProps = {
  complete: () => void
  close: () => void
}

const CallbackModal = ({ close, complete }: TProps) => {
  const t = useTranslations()

  const {
    register,
    handleSubmit,
    setError,
    reset,
    control,
    formState: { isSubmitSuccessful, isLoading, isSubmitting, errors },
  } = useForm<FormValues>()

  useEffect(() => {
    reset({
      name: '',
      phone: '',
    })
  }, [isSubmitSuccessful, reset])

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const response = await fetch('/api/callback', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })

    if (!response.ok) {
      if (response?.status == 422) {
        const errorData = await response.json();
        (Object.keys(errorData.errors) as Array<keyof FormValues>).forEach((key, i) => {
          setError(key, { type: 'custom', message: errorData.errors[key].join(' ') })
        })
      }
    } else {
      complete()
    }
  }

  const footer = (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12'>
          <button
            type='submit'
            className='green-btn'
            form='callback-form'
            disabled={isLoading || isSubmitting}
          >
            {t('dialogs.callback-modal.send')}
          </button>
        </div>
      </div>
    </div>
  )

  return (
    <Modal title={t('dialogs.callback-modal.title')} footer={footer} close={close}>
      <form onSubmit={handleSubmit(onSubmit)} id={'callback-form'}>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12 col-md-6 position-relative'>
              <label htmlFor='name'>{t('dialogs.callback-modal.form.name-label')}</label>
              <input
                {...register('name', { required: t('validation.required', { attribute: t('validation.attributes.name') }) })}
                type='text'
                id='name'
                placeholder={t('dialogs.callback-modal.form.name-placeholder')}
                disabled={isLoading || isSubmitting}
              />
              {errors.name && <div className='error-message'>{errors.name.message}</div>}
            </div>
            <div className='col-12 col-md-6 position-relative'>
              <label htmlFor='phone'>
                {t('dialogs.callback-modal.form.phone-label')}
              </label>

              <Controller
                control={control}
                name={'phone'}
                rules={{ required: t('validation.required', { attribute: t('validation.attributes.phone') })}}
                render={({field: {onChange, onBlur, value}}) => (
                  <PatternFormat
                    id='phone'
                    format='+38 (0##) ###-##-##'
                    mask='_'
                    allowEmptyFormatting={true}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={isLoading || isSubmitting}
                  />
                )}
              />

              {errors.phone && <div className='error-message'>{errors.phone.message}</div>}
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default CallbackModal
