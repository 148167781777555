import { ISetting } from '@/entities/setting'

export interface ISettingsResponse extends Array<ISetting>{}

export enum ESettingKey {
  CURRENCY_EUR = 'currencyEur',
  CURRENCY_USD = 'currencyUsd',
  SOCIAL_FACEBOOK = 'socialFacebookLink',
  SOCIAL_YOUTUBE = 'socialYoutubeLink',
  SOCIAL_INSTAGRAM = 'socialInstagramLink',
  PHONE = 'phone',
  EMAIL = 'email',
  WORK_SCHEDULE = 'workSchedule',
  ADDRESS = 'address',
  MAP_LOCATION = 'mapLocation'
}
