'use client'

import { IBadge, IProductImage, IProductProperty } from '@/entities'
import Image from 'next/image'
import NextLink from 'next/link'
import Badge from '@/components/smart/Badge'
import classNames from 'classnames'
import { EPropertyOption } from '@/types/core'

type TProps = {
  title: string,
  alias: string,
  cover: string,
  images: IProductImage[],
  badges: IBadge[],
  price?: string | null,
  discount?: string | null,
  properties?: IProductProperty[],
}

const ProductCard = ({ title, alias, cover, images, badges, price, discount, properties }: TProps) => {
  const propertyValueIds = properties?.filter(x => !!x.value).map(x => x.value.id);

  const isProductHasEngineDiesel = !!propertyValueIds?.includes(EPropertyOption.PROPERTY_OPTION_DIESEL);
  const isProductHasEngineGasoline = !!propertyValueIds?.includes(EPropertyOption.PROPERTY_OPTION_GASOLINE);
  const isProductHasEngineElectric = !!propertyValueIds?.includes(EPropertyOption.PROPERTY_OPTION_ELECTRIC);

  return (
    <div className='inner-wrap d-flex flex-column'>
      <NextLink
        href={`/products/${alias}`}
        className='read-more'
      >
        {title}
      </NextLink>

      <div className='top-content flex-grow-1'>
        <div className={classNames('position-relative img-box', {'with-hover': images.length > 1})}>
          <Image
            src={cover}
            alt={title}
            width={400}
            height={400}
            quality={100}
          />

          {images.length > 1 && (
            <Image
              src={images[1].location}
              alt={title}
              width={400}
              height={400}
              quality={100}
            />)
          }
        </div>

				{badges.length > 0 && (
          <Badge
            title={badges[0].title}
            textColor={badges[0].textColor}
            backgroundColor={badges[0].backgroundColor}
          />
        )}

        {isProductHasEngineDiesel && <div className='type-of-drive motor-type'></div>}
        {isProductHasEngineGasoline && <div className='type-of-drive fuel-type'></div>}
        {isProductHasEngineElectric && <div className='type-of-drive electric-type'></div>}
      </div>
      <div className='bottom-content'>
        <div className='title-box'>
          {title}
        </div>
        <div className='price-box text-end'>
          {discount && <div className='old-price'>{price}</div>}
          <div className={classNames('price', {'red-price': !!discount})}>{discount ?? price}</div>
        </div>
      </div>
    </div>
  )
}

export default ProductCard
