'use client'

import Modal from '@/components/simple/Modal'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useTranslations } from 'next-intl'
import Select from 'react-select'
import { Stars } from '@/components/simple'
import { IOrderProduct } from '@/entities'


type FormValues = {
  text: string,
  rating: number,
  product_id: { label: string; value: number },
}

type TProps = {
  orderId: number,
  products: IOrderProduct[],
  complete: () => void
  close: () => void
}

const OrderReviewModal = ({orderId, complete, close, products}: TProps) => {
  // TODO: Code
  const t = useTranslations()

  const [starsCount, setStarsCount] = useState<number>()
  const labels = [
    t('dialogs.leave-a-review-modal.rating.star-1'),
    t('dialogs.leave-a-review-modal.rating.star-2'),
    t('dialogs.leave-a-review-modal.rating.star-3'),
    t('dialogs.leave-a-review-modal.rating.star-4'),
    t('dialogs.leave-a-review-modal.rating.star-5'),
  ]

  const {
    register,
    control,
    handleSubmit,
    setError,
    reset,
    formState: { isSubmitSuccessful, isLoading, isSubmitting, errors }
  } = useForm<FormValues>();

  useEffect(() => {
    reset({
      text: '',
      rating: undefined,
      product_id: undefined,
    })
  }, [isSubmitSuccessful, reset])

  const onSubmit:SubmitHandler<FormValues> = async data => {
    const response = await fetch(`/api/account/orders/${orderId}/review`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...data, 
        product_id: data.product_id.value,
        rating: starsCount,
      }),
    })

    if (!response.ok) {
      if (response?.status == 422) {
        const errorData = await response.json();
        (Object.keys(errorData.errors) as Array<keyof FormValues>).forEach((key, i) => {
          setError(key, { type: 'custom', message: errorData.errors[key].join(' ') });
        })
      }
    } else {
      complete()
    }
  };

  const footer = (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12'>
          <button
            type='submit'
            className='green-btn d-inline-flex me-2'
            form='dealer-form'
            disabled={isLoading}
          >
            {t('dialogs.leave-a-review-modal.send')}
          </button>
          <button
            type='button'
            className='white-btn d-inline-flex'
            onClick={() => close()}
            disabled={isLoading}
          >
            {t('dialogs.leave-a-review-modal.cancel')}
          </button>
        </div>
      </div>
    </div>
  )

  return (
    <Modal
      title={t('dialogs.leave-a-review-modal.title')}
      footer={footer}
      close={close}
      className='max-790'
    >
      <form onSubmit={handleSubmit(onSubmit)} id={'dealer-form'}>
        <div className='container-fluid'>
          <div className='row'>
            <div className="col-12 position-relative">
              <label htmlFor='form-text'>{t('dialogs.leave-a-review-modal.form.rating-label')}</label>
              <Stars 
                value={starsCount}
                onChange={setStarsCount} 
                disabled={false} 
                labels={labels}
              />
              {errors.rating && <div className='error-message'>{errors.rating.message}</div>}
            </div>

            <div className="col-12 position-relative">
              <label htmlFor='form-text'>{t('dialogs.leave-a-review-modal.form.product-id-label')}</label>
              <Controller
                name="product_id"
                control={control}
                rules={{ required: t('validation.required', { attribute: t('validation.attributes.product-id') }) }}
                render={({ field }) => (
                  <Select
                    {...field}
                    classNamePrefix="react-select"
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        marginBottom: '20px',
                      }),
                    }}
                    options={products.map(({id, title}) => ({value: id!, label: title}))}
                    placeholder={t('dialogs.leave-a-review-modal.form.product-id-placeholder')}
                    isDisabled={isLoading || isSubmitting}
                  />
                )}
              />
              {errors.product_id && <div className='error-message'>{errors.product_id.message}</div>}
            </div>

            <div className="col-12 position-relative">
              <label htmlFor='form-text'>{t('dialogs.leave-a-review-modal.form.text-label')}</label>
              <textarea
                {...register('text', { required: t('validation.required', { attribute: t('validation.attributes.comment') }) })}
                cols={30}
                rows={5}
                id='form-text'
                placeholder={t('dialogs.leave-a-review-modal.form.text-placeholder')}
                disabled={isLoading || isSubmitting}
              />
              {errors.text && <div className='error-message'>{errors.text.message}</div>}
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default OrderReviewModal