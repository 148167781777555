'use client'

import Modal from '@/components/simple/Modal'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslations } from 'next-intl'
import { PatternFormat } from 'react-number-format'

type FormValues = {
  firstname: string,
  lastname: string,
  phone: string,
  email: string,
  password: string
}

type TProps = {
  openLoginModalAction: () => void
  openWelcomeModalAction: () => void
  close: () => void
}

const RegisterModal = ({openLoginModalAction, openWelcomeModalAction, close}: TProps) => {
  const t = useTranslations('dialogs.register')

  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { isSubmitSuccessful, isLoading, isSubmitting, errors },
  } = useForm<FormValues>();

  const onSubmit:SubmitHandler<FormValues> = async (data) => {
    const response = await fetch("/api/register", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })

    if (!response.ok) {
      if (response?.status == 422) {
        const errorData = await response.json();
        (Object.keys(errorData.errors) as Array<keyof FormValues>).forEach((key, i) => {
          setError(key, { type: 'custom', message: errorData.errors[key].join(' ') })
        })
      }
    } else {
      openWelcomeModalAction()
    }
  }

  const footer = (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <button
            type="submit"
            className="green-btn"
            form="login-register"
            disabled={isLoading || isSubmitting}
          >
            {t('footer.register')}
          </button>
        </div>
        <div className="col-12 text-center">
          <button
            type="button"
            className="underline-btn"
            disabled={isLoading || isSubmitting}
            onClick={openLoginModalAction}
          >
            {t('footer.login')}
          </button>
        </div>
      </div>
    </div>
  )

  return (
    <Modal title={t('form.title')} footer={footer} close={close}>
      <form onSubmit={handleSubmit(onSubmit)} id={'login-register'}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 position-relative">
              <label htmlFor="firstname">{t('form.first-name-label')}</label>
              <input
                {...register("firstname", { required: true })}
                type="text"
                id="firstname"
                placeholder={t('form.first-name-placeholder')}
                disabled={isLoading || isSubmitting}
              />
              {errors.firstname && <div className="error-message">{errors.firstname.message}</div>}
            </div>
            <div className="col-12 position-relative">
              <label htmlFor="lastname">{t('form.last-name-label')}</label>
              <input
                {...register("lastname", { required: true })}
                type="text"
                id="lastname"
                placeholder={t('form.last-name-placeholder')}
                disabled={isLoading || isSubmitting}
              />
              {errors.lastname && <div className="error-message">{errors.lastname.message}</div>}
            </div>
            <div className="col-12 position-relative">
              <label htmlFor="phone">
                {t('form.phone-label')}
              </label>

              <Controller
                control={control}
                name={'phone'}
                rules={{ required: t('validation.required', { attribute: t('validation.attributes.phone') })}}
                render={({field: {onChange, onBlur, value}}) => (
                  <PatternFormat
                    id='phone'
                    format='+38 (0##) ###-##-##'
                    mask='_'
                    allowEmptyFormatting={true}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={isLoading || isSubmitting}
                  />
                )}
              />

              {errors.phone && <div className="error-message">{errors.phone.message}</div>}
            </div>
            <div className="col-12 position-relative">
              <label htmlFor="email">{t('form.email-label')}</label>
              <input
                {...register("email", { required: true })}
                type="email"
                id="email"
                placeholder="mykola@gmail.com"
                disabled={isLoading || isSubmitting}
              />
              {errors.email && <div className="error-message">{errors.email.message}</div>}
            </div>
            <div className="col-12 position-relative">
              <label htmlFor="password">{t('form.password-label')}</label>
              <input
                {...register("password", { required: true })}
                type="password"
                id="password"
                placeholder="*******"
                disabled={isLoading || isSubmitting}
              />
              {errors.password && <div className="error-message">{errors.password.message}</div>}
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default RegisterModal
