'use client'

import Modal from '@/components/simple/Modal'
import { SubmitHandler, useForm } from 'react-hook-form'
import { signIn } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/navigation'

type FormValues = {
  email: string,
  password: string
}

type TProps = {
  openRegisterModalAction: () => void
  openOTPLoginModalAction: () => void
  openForgotPasswordModalAction: () => void
  close: () => void
}

const LoginModal = ({
  openRegisterModalAction, 
  openOTPLoginModalAction, 
  openForgotPasswordModalAction,
  close
}: TProps) => {
  const t = useTranslations('dialogs.login')
  const router = useRouter();

  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitSuccessful, isLoading, isSubmitting, errors },
  } = useForm<FormValues>();

  const onSubmit:SubmitHandler<FormValues> = async data => {
    const signInResponse = await signIn('email-and-password', {
      redirect: false,
      // callbackUrl: '/account',
      ...data,
    })

    if (signInResponse) {
      if (signInResponse?.status >= 400) {
        setError('email', { type: 'custom', message: t('error-message') })
      } else {
        router.push("/account");
      }
    }
  };

  const footer = (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <button
            type="submit"
            className="green-btn"
            form="login-form"
            disabled={isLoading || isSubmitting}
          >
            {t('footer.enter')}
          </button>
        </div>
        <div className="col-6">
          <button
            type="button"
            className="underline-btn"
            onClick={openForgotPasswordModalAction}
            disabled={isLoading || isSubmitting}
          >
             {t('footer.remind-password')}
          </button>
        </div>
        <div className='col-6 d-flex justify-content-end'>
          <button
            type='button'
            className='underline-btn'
            onClick={openRegisterModalAction}
            disabled={isLoading || isSubmitting}
          >
            {t('footer.register')}
          </button>
        </div>
        <div className='col-12'>
          <button
            type='button'
            className='underline-btn'
            onClick={openOTPLoginModalAction}
            disabled={isLoading || isSubmitting}
          >
            {t('footer.login-by-phone')}
          </button>
        </div>
      </div>
    </div>
  )

  return (
    <Modal title={t('title')} footer={footer} close={close}>
      <form onSubmit={handleSubmit(onSubmit)} id={'login-form'}>
        <div className='container-fluid'>
          <div className="row">
            <div className="col-12">
              <label htmlFor="mail">{t('form.email-label')}</label>
              <input
                {...register("email", { required: true })}
                type="text"
                id="mail"
                placeholder="mykola@gmail.com"
                disabled={isLoading || isSubmitting}
              />

              {errors.email && <div className='error-message'>{errors.email.message}</div>}
            </div>
            <div className="col-12">
              <label htmlFor="password">{t('form.password-label')}</label>
              <input
                {...register("password", { required: true })}
                type="password"
                id="password"
                placeholder="******"
                disabled={isLoading || isSubmitting}
              />

              {errors.password && <div className='error-message'>{errors.password.message}</div>}
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default LoginModal
