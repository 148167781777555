'use client'

import Modal from '@/components/simple/Modal'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { useTranslations } from 'next-intl'
import { PatternFormat } from 'react-number-format'

type FormValues = {
  name: string,
  phone: string,
  text: string,
}

type TProps = {
  complete: () => void
  close: () => void
}

const DealerModal = ({ close, complete }: TProps) => {
  const t = useTranslations()

  const {
    register,
    handleSubmit,
    setError,
    reset,
    control,
    formState: { isSubmitSuccessful, isLoading, isSubmitting, errors },
  } = useForm<FormValues>()

  useEffect(() => {
    reset({
      name: '',
      phone: '',
      text: '',
    })
  }, [isSubmitSuccessful, reset])

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const response = await fetch('/api/dealer', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })

    if (!response.ok) {
      if (response?.status == 422) {
        const errorData = await response.json();
        (Object.keys(errorData.errors) as Array<keyof FormValues>).forEach((key, i) => {
          setError(key, { type: 'custom', message: errorData.errors[key].join(' ') })
        })
      }
    } else {
      complete()
    }
  }

  const footer = (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12'>
          <button
            type='submit'
            className='green-btn d-inline-flex me-2'
            form='dealer-form'
            disabled={isLoading || isSubmitting}
          >
            {t('pages.dealers.become-dealer-form.send')}
          </button>
          <button
            type='button'
            className='white-btn d-inline-flex'
            onClick={() => close()}
            disabled={isLoading || isSubmitting}
          >
            {t('pages.dealers.become-dealer-form.cancel')}
          </button>
        </div>
      </div>
    </div>
  )

  return (
    <Modal
      title={t('pages.dealers.become-dealer-form.title')}
      footer={footer}
      close={close}
      className='max-790'
    >
      <form onSubmit={handleSubmit(onSubmit)} id={'dealer-form'}>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12 col-lg-6 position-relative'>
              <label htmlFor='form-name'>{t('pages.dealers.become-dealer-form.name-label')} <span>*</span></label>
              <input
                {...register('name', { required: t('validation.required', { attribute: t('validation.attributes.name') }) })}
                type='text'
                id='form-name'
                placeholder={t('pages.dealers.become-dealer-form.name-placeholder')}
                disabled={isLoading || isSubmitting}
              />
              {errors.name && <div className='error-message'>{errors.name.message}</div>}
            </div>

            <div className='col-12 col-lg-6 position-relative'>
              <label htmlFor='phone'>
                {t('pages.dealers.become-dealer-form.phone-label')} <span>*</span>
              </label>

              <Controller
                control={control}
                name={'phone'}
                rules={{ required: t('validation.required', { attribute: t('validation.attributes.phone') })}}
                render={({field: {onChange, onBlur, value}}) => (
                  <PatternFormat
                    id='phone'
                    format='+38 (0##) ###-##-##'
                    mask='_'
                    allowEmptyFormatting={true}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={isLoading || isSubmitting}
                  />
                )}
              />

              {errors.phone && <div className='error-message'>{errors.phone.message}</div>}
            </div>

            <div className='col-12 position-relative'>
              <label htmlFor='form-text'>{t('pages.dealers.become-dealer-form.comment-label')}</label>
              <textarea
                {...register('text', { required: t('validation.required', { attribute: t('validation.attributes.comment') }) })}
                id='form-text'
                cols={30}
                rows={5}
                placeholder={t('pages.dealers.become-dealer-form.comment-placeholder')}
                disabled={isLoading || isSubmitting}
              ></textarea>
              {errors.text && <div className='error-message'>{errors.text.message}</div>}
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default DealerModal
