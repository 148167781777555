'use client'

import Modal from '@/components/simple/Modal'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { useEffect } from 'react'
import Select from 'react-select'
import { useTranslations } from 'next-intl'
import { IOrderProduct } from '@/entities'

type FormValues = {
  name: string,
  phone: string,
  message: string,
  product_id: { label: string; value: number },
}

type TProps = {
  orderId: number
  products: IOrderProduct[],
  complete: () => void
  close: () => void
}

const OrderServiceApplicationModal = ({close, complete, orderId, products}: TProps) => {
  const t = useTranslations()

  const {
    register,
    control,
    handleSubmit,
    setError,
    reset,
    formState: { isSubmitSuccessful, isLoading, isSubmitting, errors }
  } = useForm<FormValues>();

  useEffect(() => {
    reset({
      name: '',
      phone: '',
      message: '',
      product_id: undefined,
    })
  }, [isSubmitSuccessful, reset])

  const onSubmit:SubmitHandler<FormValues> = async data => {

    const response = await fetch(`/api/account/orders/${orderId}/service`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({...data, product_id: data.product_id.value}),
    })

    if (!response.ok) {
      if (response?.status == 422) {
        const errorData = await response.json();
        (Object.keys(errorData.errors) as Array<keyof FormValues>).forEach((key, i) => {
          setError(key, { type: 'custom', message: errorData.errors[key].join(' ') });
        })
      }
    } else {
      complete()
    }
  };

  const footer = (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12'>
          <div className="wrap-arrows-button">
            <button
              type='submit'
              className='green-btn d-inline-flex me-2'
              form='service-form'
              disabled={isLoading || isSubmitting}
            >
              {t('dialogs.application-for-service-modal.send')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <Modal
      title={t('dialogs.application-for-service-modal.title')}
      footer={footer}
      close={close}
      className='max-790'
    >
      <form onSubmit={handleSubmit(onSubmit)} id={'service-form'}>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12 col-lg-6 position-relative'>
              <label htmlFor='form-name'>{t('dialogs.application-for-service-modal.form.name-label')} <span>*</span></label>
              <input
                {...register('name', { required: t('validation.required', { attribute: t('validation.attributes.name') }) })}
                type='text'
                id='form-name'
                placeholder={t('dialogs.application-for-service-modal.form.name-placeholder')}
                disabled={isLoading || isSubmitting}
              />
              {errors.name && <div className='error-message'>{errors.name.message}</div>}
            </div>

            <div className='col-12 col-lg-6 position-relative'>
              <label htmlFor='form-tel'>{t('dialogs.application-for-service-modal.form.phone-label')} <span>*</span></label>
              <input
                {...register('phone', { required: t('validation.required', { attribute: t('validation.attributes.phone') }) })}
                type='tel'
                id='form-tel'
                placeholder='+38 050 123-45-67'
                disabled={isLoading || isSubmitting}
              />
              {errors.phone && <div className='error-message'>{errors.phone.message}</div>}
            </div>

            <div className='col-12 position-relative'>
              <label htmlFor='form-text'>{t('dialogs.leave-a-review-modal.form.product-id-label')}</label>
              <Controller
                name="product_id"
                control={control}
                rules={{ required: t('validation.required', { attribute: t('validation.attributes.product-id') }) }}
                render={({ field }) => (
                  <Select
                    {...field}
                    classNamePrefix="react-select"
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        marginBottom: '20px',
                      }),
                    }}
                    options={products.map(({id, title}) => ({value: id!, label: title}))}
                    placeholder={t('dialogs.leave-a-review-modal.form.product-id-placeholder')}
                    isDisabled={isLoading || isSubmitting}
                  />
                )}
              />
              {errors.product_id && <div className='error-message'>{errors.product_id.message}</div>}
            </div>

            <div className='col-12 position-relative'>
              <label htmlFor='form-text'>{t('dialogs.application-for-service-modal.form.text-label')}</label>
              <textarea
                {...register('message', { required: t('validation.required', { attribute: t('validation.attributes.comment') }) })}
                id='form-text'
                cols={30}
                rows={5}
                placeholder={t('dialogs.application-for-service-modal.form.text-placeholder')}
                disabled={isLoading || isSubmitting}
              ></textarea>
              {errors.message && <div className='error-message'>{errors.message.message}</div>}
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default OrderServiceApplicationModal
