import Modal from '@/components/simple/Modal'
import { useTranslations } from 'next-intl'

type TProps = {
  close: () => void
}

const OrderServiceApplicationSuccessModal = ({close}: TProps) => {
  const t = useTranslations("dialogs.application-for-service-success-modal");

  const footer = (
    <div className="container-fluid">
      <div className="row">
        <div className="row">
          <div className="col-12">
            <div className="wrap-arrows-button">
              <button
                type="button"
                className="green-btn"
                onClick={close}
              >
                {t('ok')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <Modal title={t('title')} footer={footer} close={close}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <p className="text-dark p-0 m-0">{t.rich("message", { br: () => <br/>})}</p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default OrderServiceApplicationSuccessModal